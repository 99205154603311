//export const URLAPI = "https://pruebas.back.ingreso.orocash.ec";
export const URLAPI = "https://back.ingreso.orocash.ec";

//export const WEBSOCKET = "wss://pruebas.back.ingreso.orocash.ec";
export const WEBSOCKET = "wss://back.ingreso.orocash.ec";

export const LOGIN_PATH = "/token/";
export const TASK_PATH = "/employee/task/";

export const GET_SETTINGS_ALL_PATH = "/settings/";

export const COMPANY_PATH = "/company/list/";
export const COMPANY_UPDATE_PATH = "/company/update/";
export const COMPANY_CREATE_PATH = "/company/create/";

export const ESTABLISHMENT_PATH = "/establishment/list/";
export const ESTABLISHMENT_UPDATE_PATH = "/establishment/update/";
export const ESTABLISHMENT_CREATE_PATH = "/establishment/create/";
export const ZONE_PATH = "/zone/list/";
export const ZONE_UPDATE_PATH = "/zone/update/";
export const ZONE_CREATE_PATH = "/zone/create/";
export const EMPLOYEE_PATH = "/employee/list/";
export const EMPLOYEE_UPDATE_PATH = "/employee/update/";
export const EMPLOYEE_CREATE_PATH = "/employee/create/";
export const EMPLOYEE_CREATE_LINK_PATH = "/employee/photo-upload-link/create/";
export const EMPLOYEE_UPDATEPHOTO_PATH = "/employee/updatephoto/";
export const EMPLOYEE_IMPORT_PATH = "/employee/import/";
export const EMPLOYEE_REPORT_PATH = "/employee/report/";
export const EMPLOYEE_REPORT_CHECK_PATH = "/employee/report/check/";
export const EMPLOYEE_VALIDATE_LINK_PATH = "/employee/validate-link-photo/";
export const SEND_EMAIL_SETTING_PATH = "/employee/send-email-setting/";

export const LOGS_EMPLOYEE_PATH = "/employee/logs/";
export const REPORT_LOGS_EMPLOYEES_PATH = "/employee/report-logs/";

export const EMAIL_SETTING_PATH = "/employee/email-setting/list/";
export const EMAIL_SETTING_UPDATE_PATH = "/employee/email-setting/update/";
export const EMAIL_SETTING_CREATE_PATH = "/employee/email-setting/create/";
export const EMAIL_SETTING_DELETE_PATH = "/employee/email-setting/delete/";

export const JOBS_PATH = "/job/list/";
export const JOBS_UPDATE_PATH = "/job/update/";
export const JOBS_CREATE_PATH = "/job/create/";

export const SUPPLIER_PATH = "/supplier/list/";
export const SUPPLIER_UPDATE_PATH = "/supplier/update/";
export const SUPPLIER_DELETE_PATH = "/supplier/delete/";
export const SUPPLIER_CREATE_PATH = "/supplier/create/";

export const USERS_PATH = "/user/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = "/user/permission-list/";
export const USER_CREATE_GROUP_PATH = "/user/group-create/";
export const USER_UPDATE_GROUP_PATH = "/user/group-update/";
export const USER_ACTIVATE_PATH = "/user/activate/";
export const USER_LOGOUT_PATH = "/user/logout/";
export const USER_PASSWORD_RESET_PATH = "/user/password/reset/";
export const USER_PASSWORD_RESET_CONFIRM_PATH = "/user/password/reset/confirm/";

export const VISITANT_PATH = "/visitant/list/";
export const VISITANT_UPDATE_PATH = "/visitant/update/";
export const VISITANT_DELETE_PATH = "/visitant/delete/";
export const VISITANT_CREATE_PATH = "/visitant/create/";

export const VISITS_PATH = "/visit/list/";
export const VISITS_UPDATE_PATH = "/visit/update/";
export const SEARCH_VISITANT_PATH =
  "/employee/employees-supplier-visitant-list/";
export const VISITS_CREATE_PATH = "/visit/create/";
export const VISITS_REPORT_PATH = "/visit/report/";
export const VISITS_REPORT_CHECK_PATH = "/visit/report/check/";
export const VISIT_MONITORING_REPORT_PATH = "/visit/monitoring/report/";
export const VISIT_MONITORING_REPORT_CHECK_PATH = "/visit/monitoring/report/check/";
export const VISITS_DETAILS_PATH = "/visit/detail/";
export const VISITS_REQUIRED_EXIT_PATH = "/visit/required-exit/";
export const VISITS_RESPONSE_EXIT_PATH = "/visit/response-exit/";


export const DEPARTURES_PATH = "/departure/employee/list/";
export const DEPARTURE_DETAILS_PATH = "/departure/employee/detail/";
export const DEPARTURES_EMERGENCY_CREATE_PATH =
  "/departure/employee/emergency/create/";
export const DEPARTURES_SCHEDULED_CREATE_PATH =
  "/departure/employee/scheduled/create/";
  export const DEPARTURES_RECURRENT_CREATE_PATH =
  "/departure/recurring/create/";
export const DEPARTURES_APPROVED_DENIED_PATH = "/departure/employee/approved-denied/";
export const DEPARTURES_ACCEPT_DECLINE_PATH = "/departure/employee/approval/";
export const DEPARTURES_UPDATE_PATH = "/departure/employee/required/";
export const ABSENCE_DAYS_PATH = "/departure/employee/absence-days/update/"
export const DEPARTURES_REPORT_PATH = "/departure/employee/report/";
export const DEPARTURES_REPORT_CHECK_PATH = "/departure/employee/report/check/";

export const VISITS_DEPARTURES_MONITORING_PATH = "/visit/list-monitoring/"

export const DEPARTURES_REASONS_TYPES_PATH = "/departure/list/";
export const DEPARTURES_REASONS_PATH = "/departure/reasons/list/";
export const DEPARTURES_REASONS_UPDATE_PATH = "/departure/reason/update/";
export const DEPARTURES_REASONS_CREATE_PATH = "/departure/reason/create/";

export const REASON_DECLINE_PATH = "/departure/reason-decline/list/";
export const REASON_DECLINE_UPDATE_PATH = "/departure/reason-decline/update/";
export const REASON_DECLINE_CREATE_PATH = "/departure/reason-decline/create/";

export const INCOME_PATH = "/income/list/";
export const REQUEST_OPENING_PATH = "/income/request-opening/";
export const REQUEST_APPROVAL_PATH = "/income/request-opening-approval/";
export const INCOME_UPDATE_PATH = "/income/update/";
export const INCOME_CREATE_PATH = "/income/create/";
export const INCOME_DETAILS_PATH = "/income/detail/";
export const INCOME_REPORT_PATH = "/income/report/";
export const INCOME_REPORT_CHECK_PATH = "/income/report/check/";

export const EMAIL_CREATE_PATH = "/zone/email/create/";
export const EMAIL_UPDATE_PATH = "/zone/email/update/";
export const EMAIL_DELETE_PATH = "/zone/email/delete/";

export const BANK_PATH = "/bank/list/";
export const BANK_CREATE_PATH = "/bank/create/";
export const BANK_UPDATE_PATH = "/bank/update/";


const BASE_CREADO = "CREADO";
const BASE_EN_ESPERA = "EN ESPERA";
const BASE_PENDIENTE_VISITA_ZONAL = "PENDIENTE VISITA ZONAL";
const SALIDA_REQUERIDA = "SALIDA REQUERIDA";
const BASE_APROBADO = "APROBADO";
const BASE_ANULADO = "ANULADO";
const BASE_CANCELADO = "CANCELADO";
const BASE_CADUCADO = "CADUCADO";
const SALIDA_APROBADA = "SALIDA APROBADA";
const SALIDA_DENEGADA = "SALIDA DENEGADA";
const SALIDA_RECHAZADA = "SALIDA RECHAZADA";
const RETORNO_RECHAZADO = "RETORNO RECHAZADO";
const RETORNO_REQUERIDO = "RETORNO REQUERIDO";
const SALIDA_ACEPTADA = "SALIDA ACEPTADA";
const RETORNO_ACEPTADO = "RETORNO ACEPTADO";
const INGRESO_REQUERIDO = "INGRESO REQUERIDO";
const INGRESO_APROBADO = "INGRESO APROBADO";
const PENDIENTE_APROBAR = "PENDIENTE APROBAR";
const INGRESO_ACEPTADO = "INGRESO ACEPTADO";

export const get_status = (status) => {
  switch (status) {
    case BASE_CREADO:
      return "primary";
    case BASE_EN_ESPERA:
      return "warning";
    case BASE_PENDIENTE_VISITA_ZONAL:
      return "warning";
    case SALIDA_REQUERIDA:
      return "warning";
    case BASE_APROBADO:
      return "success";
    case BASE_ANULADO:
      return "danger";
    case BASE_CANCELADO:
      return "danger";
    case BASE_CADUCADO:
      return "dark";
    case SALIDA_APROBADA:
        return "success";
    case SALIDA_DENEGADA:
      return "danger";
      case SALIDA_RECHAZADA:
      return "danger";
    case RETORNO_RECHAZADO:
      return "warning";
    case RETORNO_REQUERIDO:
      return "warning";
    case SALIDA_ACEPTADA:
      return "success";
    case RETORNO_ACEPTADO:
      return "success";
    case INGRESO_REQUERIDO:
        return "warning";
    case PENDIENTE_APROBAR:
        return "warning";
    case INGRESO_APROBADO:
        return "info";
    case INGRESO_ACEPTADO:
        return "success";
    default:
      return "primary";
  }
};

export const TYPE_EMPLOYEE = "EMPLOYEE";
export const TYPE_VISITANT = "VISITANT";
export const TYPE_ALL = "ALL";
export const TYPE_COMPANY = "COMPANY";

export const APP_NAME = "Sistema de Ingreso";

export const URL_FILE_UPLOAD_EMPLOYEE = "URL_FILE_UPLOAD_EMPLOYEE";
